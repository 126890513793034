import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import { Link } from "./i18n"

type Props = {
  text: string
}

const useStyles = makeStyles((theme) => ({
  fourOfourLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}))

export default function NotFoundPageHomepageLink({ text }: Props) {
  const classes = useStyles()

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <Typography component="p" gutterBottom variant="h3">
        <Link className={classes.fourOfourLink} to="/">
          {text}
        </Link>
      </Typography>
    </Grid>
  )
}
