import { makeStyles } from "@material-ui/core/styles"
import React from "react"

type Props = {
  sourceUrl: string | undefined
}

const LONG_AWS_LINK =
  "https://s3.eu-central-1.amazonaws.com/assets.fitnessmlyn.pl/"
const SHORT_AWS_LINK = "https://assets.fitnessmlyn.pl/"

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  fullMaxWidth: {
    maxWidth: "100%",
  },
})

export default function FeaturedImage({ sourceUrl }: Props) {
  const classes = useStyles()

  if (sourceUrl == null) {
    return null
  }

  const src = sourceUrl.startsWith(LONG_AWS_LINK)
    ? sourceUrl.replace(LONG_AWS_LINK, SHORT_AWS_LINK)
    : sourceUrl

  return (
    <div className={classes.container}>
      <img alt="" className={classes.fullMaxWidth} src={src} />
    </div>
  )
}
