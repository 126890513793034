import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import React from "react"

import { Language } from "../components/i18n/types"
import FeaturedImage from "../components/Image/FeaturedImage"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import useCommonStyles from "../library/useCommonStyles"
import FourHunderdAndFourEn from "../pages/404.en"
import FourHunderdAndFourPl from "../pages/404.pl"
import { WordpressPageQuery } from "../types/schema"

type Props = {
  children?: React.ReactNode
  data: WordpressPageQuery
  language: Language
  options?: {
    centered: boolean
  }
}

const useStyles = makeStyles((theme) => ({
  centerText: {
    textAlign: "center",
  },
  inheritText: {
    textAlign: "inherit",
  },
  pageText: {
    ".has-text-align-center": {
      textAlign: "center",
    },
    a: {
      color: theme.palette.primary.main,
    },
  },
}))

export const fragment = graphql`
  fragment WordpressPageFragment on WpPage {
    id
    content
    featuredImage {
      node {
        sourceUrl
      }
    }
    title
  }
`

export default function TextPage({ children, data, language, options }: Props) {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  if (data.wpPage == null) {
    return language === "pl" ? (
      <FourHunderdAndFourPl />
    ) : (
      <FourHunderdAndFourEn />
    )
  }

  const {
    wpPage: { content, featuredImage, title },
  } = data

  const sourceUrl = featuredImage?.node?.sourceUrl

  return (
    <Layout>
      <Seo />
      <Container className={commonClasses.pageContainer}>
        <FeaturedImage sourceUrl={sourceUrl} />
        <Typography
          className={
            options?.centered ? classes.centerText : classes.inheritText
          }
          component="h3"
          dangerouslySetInnerHTML={{ __html: title }}
          variant="h3"
        />
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: content }}
          variant="body1"
        />
        {children}
      </Container>
    </Layout>
  )
}
