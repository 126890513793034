import { useContext, useEffect } from "react"

import { LanguageContext } from "../components/i18n"

export default function useEnglish() {
  const { setLanguage } = useContext(LanguageContext)

  useEffect(() => {
    setLanguage("en")
  }, [setLanguage])
}
