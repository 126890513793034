import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import React from "react"

import { Link } from "./i18n"

type Button = {
  href: string
  text: string
}

type Props = {
  left: Button
  right: Button
}

const useStyles = makeStyles((theme) => ({
  navigationButtonContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  navigationButtonLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}))

export default function NavigationButtons({ left, right }: Props) {
  const classes = useStyles()

  return (
    <Grid
      alignItems="center"
      className={classes.navigationButtonContainer}
      container
      justifyContent="space-around"
    >
      <Link className={classes.navigationButtonLink} to={left.href}>
        <Typography component="p" variant="h3">
          <ArrowBackIcon fontSize="large" />
          {left.text}
        </Typography>
      </Link>
      <Link className={classes.navigationButtonLink} to={right.href}>
        <Typography component="p" variant="h3">
          {right.text}
          <ArrowForwardIcon fontSize="large" />
        </Typography>
      </Link>
    </Grid>
  )
}
