import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import NotFoundPageHomepageLink from "../components/NotFoundPageHomepageLink"
import useEnglish from "../library/useEnglish"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

export default function FourHunderdAndFourEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(
        language: { slug: { eq: "en" } }
        slug: { eq: "page-not-found-en" }
      ) {
        ...WordpressPageFragment
      }
    }
  `

  const data = useStaticQuery<WordpressPageQuery>(query)

  return (
    <TextPage data={data} language="en" options={{ centered: true }}>
      <NotFoundPageHomepageLink text="HOMEPAGE" />
    </TextPage>
  )
}
