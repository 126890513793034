import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import NotFoundPageHomepageLink from "../components/NotFoundPageHomepageLink"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

export default function FourHunderdAndFourPl() {
  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "page-not-found" }) {
        ...WordpressPageFragment
      }
    }
  `

  const data = useStaticQuery<WordpressPageQuery>(query)

  return (
    <TextPage data={data} language="pl" options={{ centered: true }}>
      <NotFoundPageHomepageLink text="STRONA GŁÓWNA" />
    </TextPage>
  )
}
